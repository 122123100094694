// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

// jquery
import jquery from "jquery"
window.jQuery = jquery
window.$ = jquery

// bootstrap
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

// fontawesome
import "@fortawesome/fontawesome-free/js/all"
